<template>
    <div class="product-bar">
        <div class="product-bar-image">
            <img :src="product.sku.product.versions[0].articulo_fotos[0].articulo_foto_imagen.public_path"
                 alt="product">
        </div>
        <div class="product-bar-info">
            <div class="product-bar-title">
                <p>{{ product.name }}</p>
            </div>
            <div class="product-bar-text">
                <p>SKU: <span class="product-bar-sku">{{ product.sku.sku }}</span></p>
            </div>
            <div class="product-bar-text">
                <p>Descuento: <span class="product-bar-discount">{{ product.sku.product.versions[0].descuento }}%</span>
                </p>
            </div>
            <div class="product-bar-text">
                <p>Precio:<br>
                    <span class="product-bar-price-line-through">${{ getProductPriceFromOrder(product) }} pesos</span>
                </p>
            </div>
            <div class="product-bar-text">
                <p>Precio con descuento:<br>
                    <span class="product-bar-price-discount">${{ formatPrice(product.on_sale_price) }} pesos</span></p>
            </div>
            <div class="product-bar-text">
            </div>
            <div v-if="!status.delete" class="product-bar-delete">
                <p @click="remove()">
                    <span><i class="fa-solid fa-trash-xmark"></i></span>
                    <span>Eliminar prenda</span>
                </p>
            </div>
            <div v-else>
                <span class="spinner-border spinner-border-sm" role="status">
                    <span class="sr-only">Loading...</span>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import ProductPrice from "@/components/mixins/ProductPrice";
import product from "@/components/mixins/product";

export default {
    mixins: [ProductPrice, product],
}
</script>

<style lang="scss" scoped>
.product-bar {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #efefef;
}

.product-bar-delete {
    width: 50%;
    font-size: 12px;
    text-decoration: underline;
    color: red;
    cursor: pointer;
    padding: 0 15px;

    i {
        padding-right: 10px;
    }
}

.product-bar-image {
    width: 20%;

    img {
        width: 100%;
    }
}

.product-bar-info {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
}

.product-bar-text {
    width: 50%;
    padding: 0 15px;
    color: #797979;
    font-size: 14px;
}

.product-bar-sku {
    font-weight: bolder;
    color: #4a4a4a;
}

.product-bar-discount {
    font-weight: bolder;
    color: #ff9114;
}

.product-bar-price-discount {
    font-size: 18px;
    font-weight: bolder;
    color: #143c92;
}

.product-bar-price-line-through {
    font-size: 16px;
    font-weight: bolder;
    text-decoration: line-through;
    color: #4a4a4a;
}

.product-bar-title {
    width: 100%;
    padding: 0 15px;
    color: black;
}
</style>