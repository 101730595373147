export default {
    props: {
        product: Object,
    },
    // mixins: [prices],
    data: () => ({
        status: {
            delete: false
        }
    }),
    // computed: {
    //     hasImage() {
    //         return this.product?.sku?.product?.versions.find(v => v.content_version_id === 2)?.product_image
    //     },
    // },
    methods: {
        remove() {
            // if (this.hasPendingCart) return;

            if (!this.status.delete) {
                this.status.delete = true
                this.$axios.delete(`/cart/${this.product.id}`).then(response => {
                    if (response.data.error) {
                        console.error(response.data.error)
                    } else {
                        this.$emit('removedProduct', this.product.id)
                    }
                }).finally(() => {
                    this.status.delete = false
                })
            }
        },
    }
}