
<div class="product-bar">
    <div class="product-bar-image">
        <img :src="product.sku.product.versions[0].articulo_fotos[0].articulo_foto_imagen.public_path"
             alt="product">
    </div>
    <div class="product-bar-info">
        <div class="product-bar-title">
            <p>{{ product.name }}</p>
        </div>
        <div class="product-bar-text">
            <p>SKU: <span class="product-bar-sku">{{ product.sku.sku }}</span></p>
        </div>
        <div class="product-bar-text">
            <p>Descuento: <span class="product-bar-discount">{{ product.sku.product.versions[0].descuento }}%</span>
            </p>
        </div>
        <div class="product-bar-text">
            <p>Precio:<br>
                <span class="product-bar-price-line-through">${{ getProductPriceFromOrder(product) }} pesos</span>
            </p>
        </div>
        <div class="product-bar-text">
            <p>Precio con descuento:<br>
                <span class="product-bar-price-discount">${{ formatPrice(product.on_sale_price) }} pesos</span></p>
        </div>
        <div class="product-bar-text">
        </div>
        <div v-if="!status.delete" class="product-bar-delete">
            <p @click="remove()">
                <span><i class="fa-solid fa-trash-xmark"></i></span>
                <span>Eliminar prenda</span>
            </p>
        </div>
        <div v-else>
            <span class="spinner-border spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
            </span>
        </div>
    </div>
</div>
